// jquery
import * as jQuery from "jquery";
window.jQuery = window.$ = jQuery;

// prismjs
import "prismjs";
import "prismjs/themes/prism.css";

// handlebars
import "handlebars";

// bootstrap
import "bootstrap";
import "bootstrap/../../css/bootstrap.css"

import "alpaca";
import "alpaca/../alpaca.css"

// import "../lib/jstree/jstree.min";
import "jstree";
import "jstree-bootstrap-theme/proton/style.css";

// //import "../lib/spin/spin";
import "spin";
import "jquery.spin";

// lightbox2
import "lightbox2/js/lightbox";
import "lightbox2/css/lightbox.css";

// yamm (megamenu)
import "yamm/yamm.css";

// font awesome
import "fontawesome/css/fontawesome.css";
import "fontawesome/css/brands.css";
import "fontawesome/css/solid.css";
import "fontawesome/css/duotone.css";
import "fontawesome/css/v4-shims.css";

// APP code
import "./scss/style.scss";
